<template>
	<Header />
	<div id="app">
		<div class="main-wrapper">
			<main class="main">
				<slot />
			</main>
		</div>
		<Footer no-start />
	</div>
</template>
